


import React from 'react';

import gsap from 'gsap';

import VideoPlayer from '../../../../components/VideoPlayer/VideoPlayer';

/**
 * PROPS PROGETTO
 * 
 */


import './CSVideo.scss';

class CSVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: this.props.urlSource,
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);


    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">

                <section className="casestudy__logo">
                    <div className="section-parallax-video">
                        <div className="parallax-content-video">
                            <VideoPlayer  {...this.videoOptions1}></VideoPlayer>
                        </div>
                    </div>

                </section>






            </div >

        );
    }
}

export default CSVideo;


