import React, { useEffect, useState } from 'react';
import { TimelineMax } from 'gsap';
import { Link, navigate } from 'gatsby';

import '../PreviousNextProject/PreviousNextProject.css';

const leftArrow = require('../../images/left-arrow.png');
const rightArrow = require('../../images/right-arrow.png');

var tl = new TimelineMax({
	paused: true,
});
var tl2 = new TimelineMax({
	paused: true,
});

const PreviousNextProject = props => {
	const [planetId, setPlanetId] = useState();
	const planetsUrl= [ 'idm', 'neko', 'osservatorio', 'tedxluiss', 'molendini', 'toefrom', 'quinaryo', 'apparente', 'nerarium', 'sushi-ii'];
	const planetLen = planetsUrl.length;

	const getPlanetNameFromURL = url => {
		const lastSlash = url.lastIndexOf('/');
		const len = url.length;
		let planetName = "";

		for(let i=lastSlash + 1; i<len; i++) {
			planetName += url.charAt(i) ;
		}

		return planetName;
	}

	useEffect(() => {
		tl.to('.previous-project', 0.25, { x: '-7em', scale: 0.5 });
		tl2.to('.next-project', 0.25, { x: '7em', scale: 0.5 });
		const planetName = getPlanetNameFromURL(window.location.pathname);
		setPlanetId(planetsUrl.indexOf(planetName));
	}, []);

	function moveLeft() {
		tl.play();
	}
	function resetLeft() {
		tl.reverse();
	}

	function moveRight() {
		tl2.play();
	}
	function resetRight() {
		tl2.reverse();
	}
	function goToNext(){
		const nextPlanetId = planetId + 1;
		console.log(nextPlanetId);

		if(nextPlanetId === planetLen) {
			navigate('/case-study/' + planetsUrl[0]);
		} else {
			navigate('/case-study/' + planetsUrl[nextPlanetId]);
		}
	}
	function goToPrevious(){
		const nextPlanetId = planetId - 1;

		if(nextPlanetId === -1) {
			navigate('/case-study/' + planetsUrl[planetLen - 1]);
		} else {
			navigate('/case-study/' + planetsUrl[nextPlanetId]);
		}
	}

	return (
		<div className="container previous-next">
			<div className="row">
				
				<div onClick={() => goToPrevious()} onMouseEnter={() => moveLeft()} onMouseLeave={() => resetLeft()} className="col-xs-6 col-md-6 text-left">
					<img className="left-arrow" src={leftArrow}></img>
					<div className="previous-project"></div>
					<label>missione precedente</label>
				</div>
				<div onClick={() => goToNext()} onMouseEnter={() => moveRight()} onMouseLeave={() => resetRight()} className="col-xs-6 col-md-6 text-right">
					<div className="next-project"></div>
					<label>prossima missione</label>
					<img className="right-arrow" src={rightArrow}></img>
				</div>
			</div>
		</div>
	);
};

export default PreviousNextProject;
