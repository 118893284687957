import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudyRestyleApparente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="Quinaryo"
                                    class="title--lg"

                    subtitle1={["Acquisto e vendita", <br></br>, "di titoli in criptovaluta"]}
                    tags={["Brand Identity, UI/UX, Web development"]}
                ></CSTopHead>

                {/*Video in parallax full screen
                 */}
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/XL_Quinaryo.mp4"></CSVideoFull>



                {/*Elemento in parallax full screen
                 
                <CSFullImage urlImage="url('https://images.unsplash.com/photo-1597098494674-36c3813a65f3?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ')"></CSFullImage>*/}



                <CSTextPrimary title="La sfida"
                    content="Quinaryo è una piattaforma rivoluzionaria attraverso
                    la quale per la prima volta è possibile vendere o
                    acquistare in criptovalute titoli di società non quotate
                    sui mercati tradizionali.
                    La sfida è stata comunicare la solidità del sistema Quinaryo, la facilità d’uso, la fiducia, la trasparenza e l’indiscutibile affidabilità di Quinaryo."
                    urlProject="https://www.alfatauristudio.com/quinaryo/"
                    urlIos="https://apps.apple.com/it/app/apparente/id1445134428"
                    urlGoogle=""></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Apparente/XL_apparente.mp4"></CSVideo>*/}



                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/L_Quinaryo.jpg')"></CSMediumImage>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Tra passato", <br></br>, "e futuro"]}
                    content="Le radici del logo affondano nell'antica Roma, nel suo mercato più famoso, il Foro Boario, luogo di scambio, di ingresso e uscita di beni e persone. La forma riprende il ferro battuto tondengiante del Quinario, antica moneta romana. I colori si rifanno a quelli dell'antica Roma. La fusione tra tutti questi elementi ha dato vita ad un'identità in grado di rappresentare uno stretto legale tra passato, presente e futuro. Quello dello scambio, non più in un luogo come il Foro, ma in luogo come la rete.  "

                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1=" L'importanza di concepire prodotti utilizzabili su mobile."
                    /*text2="   "*/
                    urlImage1Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/S_Quinaryo2.gif')"
                    urlImage2Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/M_Quinaryo.jpg')"
                    //urlImage3Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/M_Quinaryo2.jpg')"
                    urlImage4Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/M_Quinaryo3.jpg')"
                    //urlImage5Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/M_Quinaryo.jpg')"
                    /*urlImage6Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/S_Quinaryo.gif')"*/ ></CSParallaxElements>



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">L'identità <br>
                                </br>
                              va coordinata.</h2>
                            </div>
                        </div>
                    </div>
                    <CSMediumImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/L_Quinaryo2.jpg')"></CSMediumImage>
                </div>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Perchè solo una", <br></br>, " landing page?"]}
                    content="Una Landing Page è come un biglietto da visita: deve
                    essere breve ed immediata.
                    Il progetto è il risultato di un'attenta analisi delle ICO
                    operanti nello stesso campo.
                    Dal punto di vista grafico abbiamo fatto uso del
                    branding di Quinaryo per integrare al meglio la
                    landing page con il resto della comunicazione."

                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO*/}
                <CSParallaxElementsAlternate
                    text1=" Web version adapted for
                    smartphones."
                    text2=" Web version adapted for
                    smartphones."
                    urlImage1Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/M_Quinaryo2.jpg')"
                    //urlImage2Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/M_Quinaryo.jpg')"
                    urlImage3Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/M_Quinaryo4.jpg')"
                    urlImage4Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Quinaryo/S_Quinaryo.gif')"
                ></CSParallaxElementsAlternate>



                {/*Elemento in parallax full screen
                 
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://images.unsplash.com/photo-1597098494674-36c3813a65f3?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ')"></CSFullImage>
                </div>*/}

                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText 
                        cta=" " ></CSLastText>
                </div>

                <div className="container">

<ShareButton url={"https://www.alfatauristudio.it/case-study/quinaryo"}>


</ShareButton>
</div>



            </div >

        );
    }
}

export default CaseStudyRestyleApparente;
