

import React from 'react';

import gsap from 'gsap';


/**
 * PROPS PROGETTO
 * 
 */


import './CSLastText.scss';

class CSLastText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);


    }

    componentDidMount() {
        console.log(gsap);


    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <section className="casestudy__desc--last">
                    <div className="container">
                        <div className="row">



                            <div className=" col-xs-12 col-sm-12 col-md-6 ">
                                <div className="casestudy__desc--last__description">
                                    <p>
                                        {this.props.content}
                                    </p>
                                </div>
                                <div className="casestudy__desc--last__cta">
                                {/*
                                    <a href="#">


                                        <p>
                                            {this.props.cta}
                                        </p>
                                    </a>
 */}
                                </div>

                            </div>



                        </div>

                    </div>

                </section>






            </div >

        );
    }
}

export default CSLastText;



