import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="Neko"
                    class="title--lg"

                    subtitle1={["Un'osmosi tra oriente", <br></br>, "e sud del mondo"]}
                    tags={["Brand identity"]}
                ></CSTopHead>


                {/*Elemento in parallax full screen
                 */}
                <CSFullImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Neko/XL_Neko.jpg')"></CSFullImage>

                {/*Video in parallax full screen
                 
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Neko/XL_Neko.jpg"></CSVideoFull>*/}


                <CSTextPrimary title="La sfida"
                    content="Creare un ponte di collegamento tra terra e cielo. Una vera osmosi tra oriente e sud del mondo, come la cultura mediterranea. Il mare come elemento chiave e insostituibile, capace di unire ed influenzare popoli e culture diverse, momento di sospensione e riflessione, simbolo di libertà e nello stesso tempo smarrimento. Terra e cielo saranno elementi determinanti nella realizzazione tecnica dei capi (umidità, vento, salsedine, sole)."
                    urlIos=""
                    urlProject=""
                    urlGoogle=""></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://idmstartup.it/HOME.mp4"></CSVideo>*/}


                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Neko/L_Neko.jpg')"></CSMediumImage>


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Un carattere per esprimere", <br></br>, " il carattere di Neko "]}
                    content="Attualmente non divulgabile" //
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1="Esprimere una visione globale dell'abbigliamento."
                    text2="Il classico orientale unito allo stile occidentale."
                    urlImage1Square="url('https://alfatauristudio.com/assets/images/case-studies/Neko/S_Neko.gif')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko1.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko2.jpg')"
                    urlImage4Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko3.jpg')"
                    urlImage5Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko4.jpg')"
                    urlImage6Square="url('https://alfatauristudio.com/assets/images/case-studies/Neko/S_Neko2.jpg')" ></CSParallaxElements>




                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">I clienti vanno <br>
                                </br>
                              identificati e..soffisfatti!</h2>
                            </div>
                        </div>
                    </div>
                    <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Neko/L_Neko2.jpg')"></CSMediumImage>
                </div>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Perchè Solomon", <br></br>, " ed Helvetica"]}
                    content="I due font sono stati scelti per lavorare in sinergia e sfruttar ei punti di forza di entrambi. Solomon perfetto per i titoli. Helvetica per testi lunghi e con corpo più piccolo con l'utilizzo del regular o light."
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO*/}
                <CSParallaxElementsAlternate
                    text1=" Il mare come elemento chiave e insostituibile,
                    momento di sospensione e riflessione."
                    text2=" Simbolo di libertà e smarrimento.."
                    urlImage1Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko5.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko6.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko7.jpg')"
                    urlImage4Square="url('https://alfatauristudio.com/assets/images/case-studies/Neko/S_Neko3.jpg')"
                ></CSParallaxElementsAlternate>



                {/*Elemento in parallax full screen
                 */}
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Neko/XL_Neko.jpg')"></CSFullImage>
                </div>

                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText content=" "
                        cta="" ></CSLastText>
                </div>

                <div className="container">

                    <ShareButton url={"https://www.alfatauristudio.it/case-study/neko"}>


                    </ShareButton>
                </div>


            </div >

        );
    }
}

export default CaseStudy;
