import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="IDM - Music Startup"
                   class="title--lg"
                    subtitle1={["Il tuo progetto artistico", <br></br>, "è una startup"]}
                    tags={["Brand identity"]}
                ></CSTopHead>


                {/*Elemento in parallax full screen
                 
                <CSFullImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/IDM/XL_IDM1.mp4')"></CSFullImage>*/}


                {/*Video in parallax full screen
                 */}

                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/IDM/XL_IDM1.mp4"></CSVideoFull>

                <CSTextPrimary title="La sfida"
                    content="L'obiettivo è stato quello di realizzare un portale pensato e sviluppato a supporto degli artisti dell'era digitale. IDM - Music Startup è un progetto ideato e curato da iCompany, una piattaforma di strumenti costruita attorno ad una distribuzione digitale potenziata ed in grado di rispondere a tutte le esigenze del musicista attuale." 
                    urlIos=""
                    urlProject="https://www.musicstartup.it/beta/"
                    urlGoogle=""></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://idmstartup.it/HOME.mp4"></CSVideo>*/}

           
                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/IDM/L_IDM.jpg')"></CSMediumImage>


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Tra musica ", <br></br>, " e innovazione."]}
                    content="Ridisegnare il modo in cui artisti ed etichette vivono e progettano la loro musica attraverso nuove tecnologie e modi di intendere un ecosistema digitale,
                    pensato ad hoc per comunicare con l'interno e gli addetti ai lavori e all'esterno con artisti emergenti che hanno bisogno di consolidare il proprio progetto musicale." //
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1="Il progetto è stato adattato ad ogni dispositivo."
                    text2="Controllare il proprio progetto completamente da smartphone."
                    urlImage1Square="url('https://alfatauristudio.com/assets/images/case-studies/IDM/S_IDM1.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM1.jpg')"
                    //urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM2.jpg')"
                    urlImage4Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM4.jpg')"
                    urlImage5Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM2.jpg')"
                    //urlImage6Square="url('https://alfatauristudio.com/assets/images/case-studies/IDM/S_IDM2.jpg')" 
                    ></CSParallaxElements>
                    



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">Mobile First <br>
                                </br>
                              è infrastruttura.</h2>
                            </div>
                        </div>
                    </div>
                    <CSFullImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/IDM/XL_IDM3.jpg')"></CSFullImage>
                </div>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Perchè progettare", <br></br>, " tre prodotti"]}
                    content="I migliori sistemi sono quelli che sono in grado di svolgere le proprie funzioni in maniera autonoma senza dimenticarsi di comunicare tra di loro, perchè la comunicazione è fin da sempre il cavallo di battaglia di ogni piattaforma/sistema che si rispetti. 
                    Una landing per informare, un pannello per gestire, una piattaforma per controllare e lanciare."
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO*/}
                <CSParallaxElementsAlternate
                    text1="L'area di login va pensata come se fosse l'ingresso di casa."
                    text2="Comunicare che la musica è ritmo anche attraverso le animazioni."
                    urlImage1Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM3.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM5.gif')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/IDM/M_IDM6.jpg')"
                    urlImage4Square="url('https://alfatauristudio.com/assets/images/case-studies/IDM/S_IDM3.gif')"
                ></CSParallaxElementsAlternate>



                {/*Elemento in parallax full screen
                 */}
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/IDM/XL_IDM2.jpg')"></CSFullImage>
                </div>

                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText content=" "
                        cta="Condividi" ></CSLastText>
                </div>


                <div className="container">

                    <ShareButton url={"https://www.alfatauristudio.it/case-study/idm"}>


                    </ShareButton>
                </div>
                


            </div >

        );
    }
}

export default CaseStudy;
