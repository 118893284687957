import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="To&From"
                                    class="title--lg"

                    subtitle1={["Fai e ricevi", <br></br>, "il regalo che vuoi"]}
                    tags={["Brand identity"]}
                ></CSTopHead>


                {/*Elemento in parallax full screen
                 */}
                <CSFullImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/XL_ToeFrom.jpg')"></CSFullImage>

                {/*Video in parallax full screen
                 
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Neko/XL_Neko.jpg"></CSVideoFull>*/}


                <CSTextPrimary title="La sfida"
                    content="To&From è una social e-gifting marketplace app che facilita la realizzazione di regali originali tramite funzioni uniche. Potete acquistare prodotti ed esperienze esclusivamente Made in Italy che spaziano dai prodotti home, abbigliamento, accessori, gioielli, cosmetica a molto altro. Niente più regali indesiderati, niente più collette e niente più scuse." 
                    urlIos="https://apps.apple.com/it/app/to-from/id1511326876?I=en"
                    urlProject="https://giftstoandfrom.com/"
                    urlGoogle="https://play.google.com/store/apps/details?id=com.giftstoandfrom.app"></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://idmstartup.it/HOME.mp4"></CSVideo>*/}

           
                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/L_ToeFrom.jpg')"></CSMediumImage>


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Regalare ora", <br></br>, " è semplice "]}
                    content="L'app è stata sviluppata sul concetto che ruota attorno al regalo, al fare regali e al riceverli. Sostenendo al contempo il Made in Italy. Realizzare un social e-gifting markeplace non è mai impresa facile. É per questo abbiamo dovuto ripensare il modo di vivere l'esperienza del regalo." //
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1="Animare con l'utilizzo del corretto tono di voce e la giusta palette."
                    text2="Evadere un ordine velocemente, anche da smartphone."
                    urlImage1Square="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/S_ToeFrom1.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/M_ToeFrom1.gif')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/M_ToeFrom2.gif')"
                    urlImage4Long="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/M_ToeFrom3.jpg')"
                    urlImage5Long="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/M_ToeFrom4.jpg')"
                    urlImage6Square="url('https://alfatauristudio.com/assets/images/case-studies/ToeFrom/S_ToeFrom2.jpg')" ></CSParallaxElements>
                    



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">I clienti vanno <br>
                                </br>
                              identificati e..soffisfatti!</h2>
                            </div>
                        </div>
                    </div>
                    <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Neko/L_Neko2.jpg')"></CSMediumImage>
                </div>*/}

                {/*CONTENUTO DESCRIZIONE SECONDARIA
                <CSTextSecondary title={["Perchè Solomon", <br></br>, " ed Helvetica"]}
                    content="I due font sono stati scelti per lavorare in sinergia e sfruttar ei punti di forza di entrambi. Solomon perfetto per i titoli. Helvetica per testi lunghi e con corpo più piccolo con l'utilizzo del regular o light."
                ></CSTextSecondary>*/}


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO
                <CSParallaxElementsAlternate
                    text1=" Terminals for paying bills in stores,
                   business centers, and hotels
                   got a design upgrade."
                    text2=" Terminals for paying bills in stores,
                   business centers, and hotels
                   got a design upgrade."
                    urlImage1Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko5.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko6.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Neko/M_Neko7.jpg')"
                    urlImage4Square="url('https://alfatauristudio.com/assets/images/case-studies/Neko/S_Neko3.jpg')"
                ></CSParallaxElementsAlternate>*/}



                {/*Elemento in parallax full screen
                 
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Neko/XL_Neko.jpg')"></CSFullImage>
                </div>*/}

                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText content=" "
                        cta=" " ></CSLastText>
                </div>

                
                <div className="container">

                    <ShareButton url={"https://www.alfatauristudio.it/case-study/toefrom"}>


                    </ShareButton>
                </div>


            </div>

        );
    }
}

export default CaseStudy;
