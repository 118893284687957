import React from 'react';

import gsap from 'gsap';

/**
 * PROPS PROGETTO
 * 
 */


import './CSMediumImage.scss';

class CSMediumImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

      
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });

            console.log(this.props.urlImage);
        });


     
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy__medium">


                {/*Elemento in parallax, si può usare lo stesso elemento, 
                poi cambiare il background applicando il bg
                con la classname bg--n
                 */}

                <section className="casestudy__medium__preview__medium">
                    <div className="section-parallax">
                        <div className="parallax-content" style={{backgroundImage: this.props.urlImage }}>
                        </div>
                    </div>
                </section>


            </div >

        );
    }
}

export default CSMediumImage;
