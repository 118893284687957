import React from 'react'

import Button from '../Button/Button'

import './ShareButton.css'

const ShareButton = (props) => {

    function handleOnClick(event) {
        const el = document.createElement('textarea');
        el.value = props.url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        const copied = document.createElement('span');
        copied.innerHTML = 'Link copiato!';
        copied.className += 'copied animated faster fadeIn ';
        document.body.appendChild(copied);
        console.log(copied);

        setTimeout(() => {
            copied.className += ' fadeOut';
        }, 1500);
    }

    function handleOnMouseOver(event) {
        event.currentTarget.style.color = props.hoverColor ? props.hoverColor : '#F49131';
        if (event.currentTarget.querySelector('polyline'))
            event.currentTarget.querySelector('polyline').style.stroke = props.hoverColor ? props.hoverColor : '#F49131';
        if (event.currentTarget.querySelectorAll('g').length > 0)
            event.currentTarget.querySelectorAll('g').forEach(function(g) {
                g.style.fill = props.hoverColor ? props.hoverColor : '#F49131';
            });
    }

    function handleOnMouseOut(event) {
        event.currentTarget.style.color = props.color ? props.color : 'black';
        if (event.currentTarget.querySelector('polyline'))
            event.currentTarget.querySelector('polyline').style.stroke = props.color ? props.color : 'black';
        if (event.currentTarget.querySelectorAll('g').length > 0)
            event.currentTarget.querySelectorAll('g').forEach(function(g) {
                g.style.fill = props.color ? props.color : 'black';
            });
    }

    return (
        <Button style={{
            color: props.color ? props.color : 'black',
            fontSize: props.size ? props.size : '20px'
        }} onClick={handleOnClick} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
            <svg display={'none'} opacity={'0'} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 30 30"
            width={props.iconWidth ? props.iconWidth : '20px'} height={props.iconHeight ? props.iconHeight : '20px'}>
                <polyline
                    style={{fill: "none", stroke: props.color ? props.color : 'black',
                    strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '10' }}
                    points="  23,23 7,15 23,7 "/>
                <g style={{fill: props.color ? props.color : 'black' }}>
                    <circle cx="23" cy="7" r="4"/>
                </g>
                <g style={{fill: props.color ? props.color : 'black' }}>
                    <circle cx="23" cy="23" r="4"/>
                </g>
                <g style={{fill: props.color ? props.color : 'black' }}>
                    <circle cx="7" cy="15" r="4"/>
                </g>
            </svg> Condividi
        </Button>
    );
}

export default ShareButton;