import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudyPageTedx extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="TedxLuiss 2019"
                    class="title--sm"

                    subtitle1={["Reagire alla tremenda", <br></br>, "sconfitta della consuetudine"]}
                ></CSTopHead>

                {/*Video in parallax full screen
                 */}
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/XL_Tedx.mp4"></CSVideoFull>

                {/*Elemento in parallax full screen
                 
                <CSFullImage urlImage="url('https://images.unsplash.com/photo-1597098494674-36c3813a65f3?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ')"></CSFullImage>*/}


                <CSTextPrimary title="La sfida"
                    content="  E se i fiumi scorressero al contrario? E se scalassimo le pianure? E se ci addormentassimo la mattina e ci svegliassimo la notte?
                    Come reagiresti alla tremenda sconfitta della consuetudine?

Sentirsi in crisi, mettersi in dubbio, scomporre e ricomporre, ribaltare la visione del mondo per creare nuove soluzioni, nuovi modelli per scoprire e riscoprire noi stessi e ciò che ci circonda.

Lasciati ispirare dal cambio prospettiva.
Riusciresti a invertire te stesso?
"

                    urlProject="https://www.alfatauristudio.com/tedxluiss2019/"
                ></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://idmstartup.it/HOME.mp4"></CSVideo>
                */}

                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/L_TEDx2.jpg')"></CSMediumImage>


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Rappresentare", <br></br>, " gli stati della materia"]}
                    content="Il concept vincitore tra quelli proposti è stato quello che fa riferimento ai vari stati della materia rappresentati in maniera del tutto stilizzata e minimale attraverso la realizzazione di sei pattern differenti. I pattern differenti hanno permesso di avere una sorta di identità dinamica da utilizzare per i diversi momenti e fasi dell'evento."
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1=" I Badge realizzati per l'evento che si è tenuto il 23 Marzo."
                    text2=" Il booklet è stato stampato e distribuito per la promozione."
                    urlImage1Square="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/S_Tedx2.gif')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/M_TEDx1.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/M_TEDx2.jpg')"
                    urlImage4Long="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/M_TEDx3.jpg')"
                    urlImage5Long="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/M_TEDx4.jpg')"
                    urlImage6Square="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/S_TEDx1.jpg')" ></CSParallaxElements>



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">Upside down <br>
                                </br>
                              per davvero!</h2>
                            </div>
                        </div>
                    </div>
                    <CSVideo urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/L_TEDx4.mp4"></CSVideo>
                </div>


                <CSTextSecondary title={["Ricostruire anche", <br></br>, " il modo di fare design."]}
                    content="Nella progettazione del sito web abbiamo distrutto tutti i preconcetti di usabilità, anche il più famoso, lo scroll verso il giù, si stima che il 78% degli utenti come prima azione su un sito compia lo scroll verso il basso. Abbiamo deciso di andare contro quel 78% per sovvertire le regole e sviluppare il sito a partire dal basso."
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO
                <CSParallaxElementsAlternate
                    text1=" Terminals for paying bills in stores,
                   business centers, and hotels
                   got a design upgrade."
                    text2=" Terminals for paying bills in stores,
                   business centers, and hotels
                   got a design upgrade."
                    urlImage1Long="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-long.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-long.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-long.jpg')"
                    urlImage4Square="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-square.jpg')"
                ></CSParallaxElementsAlternate>*/}



                {/*Elemento in parallax full screen
                 */}
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Tedxluiss2019/XL_Ted3.jpg')"></CSFullImage>
                </div>


                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText content="Alfatauri studio è stato partner tecnico dell'evento. Supportando in tutta la sua interezza ogni processo di realizzazione sia online che offline."
                        cta="Condividi" ></CSLastText>
                </div>

                <div className="container">

                    <ShareButton url={"https://www.alfatauristudio.it/case-study/tedxluiss"}>


                    </ShareButton>
                </div>



            </div >

        );
    }
}

export default CaseStudyPageTedx;
