import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="Molendini"
                    class="title--lg"

                    subtitle1={["La cucina italiana", <br></br>, "al massimo livello"]}
                ></CSTopHead>


                {/*Elemento in parallax full screen
                 
                <CSFullImage urlImage="url('https://images.unsplash.com/photo-1597098494674-36c3813a65f3?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ')"></CSFullImage>*/}

                {/*Video in parallax full screen
                 */}
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Molendini/XL_molendini.mp4"></CSVideoFull>


                <CSTextPrimary title="La sfida"
                    content="  Portare la classica cucina italiana ad esprimere una visione globale della ristorazione. 
                    Regalare un'esperienza culinaria di valore, dalla cucina con menù, alla pizzeria napoletana, 
                    con una gastronimia di qualità e una grande attenzione al dettaglio, alla personalizzazione, 
                    alla sperimentazione e al cliente. Tutti valori che hanno fatto di Molendini un'identità unica in ogni sua parte. Dal font al logo. "
                    urlIos="https://www.facebook.com/molendini.food.drink" //questo poi andrebbe messo con un'altra componente che si chiama Social e va realizzata in modo che quando serve posso linkare il social, per esempio accadrà anche con to&from
                    urlProject="https://www.molendini.it/" //ti ricordo il target _blank
                    urlGoogle=""></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://idmstartup.it/HOME.mp4"></CSVideo>*/}


                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/L_molendini1.jpg')"></CSMediumImage>


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Un carattere per esprimere", <br></br>, " il carattere di Molendini "]}
                    content="Per rappresentare al meglio lidentià di Molendini, con tutti gli obiettivi che si prefiggeva di raggiungere, è stato scelto di progettare, disegnare e realizzare un carattere tipografico ad hoc. 
                    Il font realizzato, il Ventura Sans, contiene tutta la personalità di Molendini. Unicità, autenticità, espressione, contaminazione e qualità. "
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1="Esprimere una visione globale della ristorazione."
                    text2="La classica cucina italiana con soli ingredienti freschi."
                    urlImage1Square="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/S_molendini1.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/M_molendini1.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/M_molendini2.jpg')"
                    urlImage4Long="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/M_molendini3.jpg')"
                    urlImage5Long="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/M_molendini4.jpg')"
                    urlImage6Square="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/S_molendini2.jpg')" ></CSParallaxElements>



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">I clienti vanno <br>
                                </br>
                              identificati e..soffisfatti!</h2>
                            </div>
                        </div>
                    </div>
                    <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/L_molendini2.jpg')"></CSMediumImage>
                </div>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Cosa significa", <br></br>, " declinare su più suppporti"]}
                    content="Il logo di Molendini è stato pensato per essere scomposto e ricomposto ed essere applicato su tutte le tipologia di materiale. Dai biglietti da visita all'insegna del bancone. 
                    Il trapezio, che rappresenta il perimetro reale del locale è stato utilizzato più volte per creare forza nel simbolo, anche senza l'utilizzo della scritta Molendini "
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO
                <CSParallaxElementsAlternate
                    text1=" Terminals for paying bills in stores,
                   business centers, and hotels
                   got a design upgrade."
                    text2=" Terminals for paying bills in stores,
                   business centers, and hotels
                   got a design upgrade."
                    urlImage1Long="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-long.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-long.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-long.jpg')"
                    urlImage4Square="url('https://alfatauristudio.com/assets/images/case-studies/Apparente/ats-square.jpg')"
                ></CSParallaxElementsAlternate>*/}



                {/*Elemento in parallax full screen
                 */}
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Molendini/L_molendini3.jpg')"></CSFullImage>
                </div>

                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText content=" "
                        cta=" " ></CSLastText>
                </div>

                <div className="container">

                    <ShareButton url={"https://www.alfatauristudio.it/case-study/molendini"}>


                    </ShareButton>
                </div>



            </div >

        );
    }
}

export default CaseStudy;
