

import React from 'react';

import gsap from 'gsap';


/**
 * PROPS PROGETTO
 * 
 */


import './CStextSecondary.scss';

class CStextSecondary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

  
    }

    componentDidMount() {
        console.log(gsap);
       
            
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">

        
                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <section className="casestudy__desc--second">
                    <div className="container">
                        <div className="row">


                            <div className=" col-xs-12 col-sm-12 col-md-6 ">
                                <div className="casestudy__desc--second__text">
                                    <h2>{this.props.title}</h2>
                                
                                </div>

                            </div>

                            <div className=" col-xs-12 col-sm-12 col-md-6 ">
                                <div className="casestudy__desc--second__description">
                                    <p>
                                        {this.props.content}
                                    </p>
                                </div>

                            </div>



                        </div>

                    </div>

                </section>




            

            </div >

        );
    }
}

export default CStextSecondary;



