/* JS */

/* react */
import React, { useState } from 'react';
/* third part components */

/* custom components */
import Hamburger from '../../components/Hamburger/Hamburger';
import ATLayout from '../../components/ATLayout';
import Loader from '../../components/Loader/Loader';
import SEO from '../../components/seo';
import PreviousNextProject from '../../components/PreviousNextProject/PreviousNextProject';
import Footer2020 from '../../components/Footer2020/Footer2020';
import CaseStudyRestyleNeko from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleNeko';
import CaseStudyRestyleApparente from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleApparente';
import CaseStudyRestyleMolendini from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleMolendini';
import CaseStudyRestyleNerarium from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleNerarium';
import CaseStudyRestyleQuinaryo from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleQuinaryo';
import CaseStudyRestyleSushiii from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleSushiii';
import CaseStudyRestyleTedxluiss2019 from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleTedxluiss2019';
import CaseStudyRestyleIDM from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleIDM';
import CaseStudyRestyleOsservatorio from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleOsservatorio';
import CaseStudyRestyleToeFrom from '../../components/CaseStudy/CaseStudyRestyle/CaseStudyRestyleToeFrom';

import './case-study.css';
import { useEffect } from 'react';
import Footer2 from '../../components/Footer2/Footer2';

const CaseStudyPage = props => {
	let [menuOpen, setMenuOpen] = useState(false);
	let [loaded, setLoaded] = useState(false);
	let [url, setUrl] = useState(false);

	useEffect(() => {


		setTimeout(() => {
			document.body.style = 'overflow-y: hidden';
			window.scrollTo(0, 0)

			setPath();
		}, 500);

		setTimeout(() => (document.body.style = 'overflow-y: scroll'), 1000);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);

		handleOnWindowResize();
		window.addEventListener('resize', handleOnWindowResize);



		return function cleanup() {
			window.removeEventListener('resize', handleOnWindowResize);
			// observer.disconnect();
		};
	}, []);

	function setPath() {
		setUrl(props.relPath);
	}

	function handleOnHamburgerClick(event) {
		setMenuOpen(!menuOpen);
	}

	function handleOnOutsideMenuClick(event) {
		setMenuOpen(false);
	}

	var count = 0;

	function handleOnWindowResize() {

		if (count > 0) {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			} else {
				if(window.innerWidth >= 700) {
					window.location.reload();
					window.scrollTo(0, 0);
	
				}
			
			}

		}
		count++;
	}

	return (
		<ATLayout title="Case Study" isMenuOpen={menuOpen} onOutsideMenuClick={handleOnOutsideMenuClick} fixedHeader>
			<SEO title={props.title} relPath={props.relPath} description={props.description} />
			<div className="at-page at-cs-page">
				<div className="loading-header">
					{/* <Loader active absolute/> */}
					<svg
						class="hero__background"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 500 500"
						width="100%"
						height="100%"
						preserveAspectRatio="none"
					>
						<path d="M0,0 L 0,500 C68,318 412,309 500,500 L500,0 L0,0 Z" fill="white" />
					</svg>
				</div>

				{/*  Vecchio case study
				{props.children}
	*/ }

				{
					/*Integrazione nuovo CS STUDY */
				}


				{
					/*Fine integrazione nuovo CS STUDY */
				}


				{/**
				 * INSERIRE QUI LE PAGINE PER FARE PROVE
				 */}


				{(() => {
					if (url && url.includes('sushi')) {
						return (

							<CaseStudyRestyleSushiii>

							</CaseStudyRestyleSushiii>
							/*
							<CaseStudyRestyleSushiii>

							</CaseStudyRestyleSushiii>
							*/
						)
					} else if (url && url.includes('quinaryo')) {
						return (
							<CaseStudyRestyleQuinaryo>

							</CaseStudyRestyleQuinaryo>)
					} else if (url && url.includes('tedx')) {
						return (
							<CaseStudyRestyleTedxluiss2019>

							</CaseStudyRestyleTedxluiss2019>
						)

					}
					else if (url && url.includes('molendini')) {
						return (
							<CaseStudyRestyleMolendini>

							</CaseStudyRestyleMolendini>
						)

					}
					else if (url && url.includes('apparente')) {
						return (
							<CaseStudyRestyleApparente>

							</CaseStudyRestyleApparente>
						)

					}
					else if (url && url.includes('neko')) {
						return (
							<CaseStudyRestyleNeko>

							</CaseStudyRestyleNeko>
						)

					}
					else if (url && url.includes('nerarium')) {
						return (
							<CaseStudyRestyleNerarium>

							</CaseStudyRestyleNerarium>
						)

					}
					else if (url && url.includes('idm')) {
						return (
							<CaseStudyRestyleIDM>

							</CaseStudyRestyleIDM>
						)

					}
					else if (url && url.includes('osservatorio')) {
						return (
							<CaseStudyRestyleOsservatorio>

							</CaseStudyRestyleOsservatorio>
						)

					}
					else if (url && url.includes('toefrom')) {
						return (
							<CaseStudyRestyleToeFrom>

							</CaseStudyRestyleToeFrom>
						)

					}
				})()}





				<div className="at-cs-modal-page">
					<PreviousNextProject />
				</div>
				<Footer2 />

				<Hamburger open={menuOpen} onClick={handleOnHamburgerClick} />
			</div>
		</ATLayout>
	);
};

export default CaseStudyPage;
