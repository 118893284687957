import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudyRestyleApparente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="Sushiii"
                                    class="title--sm"

                    subtitle1={["Qualità e innovazione dietro", <br></br>, "ad una nuova identità"]}
                ></CSTopHead>

                {/*Video in parallax full screen
                 
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/XL_sushiii2.jpg"></CSVideoFull>*/}


                {/*Elemento in parallax full screen
                 */}

                <CSFullImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/XL_sushiii.jpg')"></CSFullImage>



                <CSTextPrimary title="La sfida"
                    content="L'obiettivo è stato quello di riportare una precedente identità sviluppata per un altro locale in un contesto più moderno. Rilanciare l'attività tramite lo sviluppo di un'app custom in modo da offrire ai propri clienti un personalissimo servizio di delivery."
                    urlProject="https://proprietari.apparente.com/"
                    urlIos="https://apps.apple.com/it/app/apparente/id1445134428"
                    urlGoogle=""></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION
                */}             
                <CSVideo urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/L_sushiii.mp4"></CSVideo>



                {/*Elemento in parallax immagine media grandezza
               
                <CSMediumImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/L_apparente1.jpg')"></CSMediumImage>  */}

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Ripetersi ", <br></br>, " è difficile"]}
                    content="Dopo l'apertura, creare qualcosa di nuovo che potesse dare ai clienti la possibilità di sentirsi di nuovo trasportati da area di novità era complicato. Con la semplificazione del logo, l'aggiunta di una palette adatta e il cambio radicale dell'impianto fotografico siamo riusciti ad imprimere una nuova forza."

                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1="L'app è stata totalmente pensata sulla base della palette presente."
                    text2="Packaging ideato per la consegna dei prodotti."
                    urlImage1Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/S_sushiii1.jpg')"
                    urlImage2Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/M_sushiii.gif')"
                    urlImage3Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/M_sushiii2.jpg')"
                    urlImage4Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/M_sushiii3.gif')"
                    urlImage5Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/M_sushiii3.jpg')"
                    urlImage6Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/S_sushiii2.jpg')" ></CSParallaxElements>



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax immagine media grandezza
                 */}
                


                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">Il design system  <br>
                                </br>
                              va sempre utilizzato.</h2>
                            </div>
                        </div>
                    </div>
                    <CSMediumImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/L_sushiii2.jpg')"></CSMediumImage>   
                </div>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Perchè realizzare", <br></br>, " una propria app?"]}
                    content="Realizzare una propria app significa dare la possibilità ai clienti di sentirsi unici e privilegiati. I vantaggi sono innumerevoli e tra questi spiccano la possibilità di declinare al meglio la propria identità visiva e fare 
                    uso delle notifiche per lavorare al meglio sui propri obiettivi di marketing"

                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO
                <CSParallaxElementsAlternate
                    text1=" Web version adapted for
                    smartphones."
                    text2=" Web version adapted for
                    smartphones."
                    urlImage1Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/M_apparente1.jpg')"
                    urlImage4Square="url(https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/S_sushiii3.gif')"
                ></CSParallaxElementsAlternate>*/}



                {/*Elemento in parallax full screen

                <div className="mt-6">
                <CSFullImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Sushiii/XL_sushiii2.jpg')"></CSFullImage>
                </div> */}

                {/*Elemento in parallax full screen
                 
                <div className="mt-4">
                    <CSLastText content=" Vele, grandi cartelloni, locandine e volantini sono stati distribuiti nella città di Roma per far conoscere il brand. La start-up ha poi ricevuto i fondi per partire."
                        cta=" " ></CSLastText>
                </div>*/}

<div className="container">

<ShareButton url={"https://www.alfatauristudio.it/case-study/sushi-ii"}>


</ShareButton>
</div>



            </div >

        );
    }
}

export default CaseStudyRestyleApparente;
