

import React from 'react';

import gsap from 'gsap';

import SplitText from "gsap/SplitText";

/**
 * PROPS PROGETTO
 * 
 */


import './CSTopHead.scss';

class CSTopHead extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
            timeLineWords: gsap.timeline(),

        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);


    }

    componentDidMount() {
        console.log(gsap);
        gsap.registerPlugin(SplitText);
        let  mySplitText =  new SplitText("#title", { type: "words,chars" });
        let  mySplitText2 =  new SplitText("#little-title", { type: "words,chars" });

        let chars = mySplitText.chars;
        let chars2 = mySplitText2.chars;
        gsap.set("#title", { perspective: 400 });
        gsap.set("#little-title", { perspective: 400 });

        this.state.timeLineWords.pause();

        this.state.timeLineWords.from(chars,
            {
                duration: 1.6, opacity: 0, scale: 0.9,
                y: 80, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, "+=0");

            
        this.state.timeLineWords.from(chars2,
            {
                duration: 1.2, opacity: 0, scale: 0.9,
                y: 80, rotationX: 8, transformOrigin: "0% 50% -50",
                ease: "back", stagger: 0.01
            }, "<0.8");
        
            setTimeout(() => {
                this.state.timeLineWords.play();
    
            }, 1200);

    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/*CONTENUTO DESCRIZIONE PRIMARIA*/}
                <div className="casestudy__tophead">
                    <div className="container casestudy__tophead__container">
                        <div className="row">
                            <div className="col-12">

                                <div className="casestudy__tophead__title" >
                                    <h1 id="little-title" >
                                        {this.props.title}
                                   </h1>
                                </div>


                                <div className="casestudy__tophead__subtitle">
                                    <h2 className={this.props.class} id="title">
                                        <span>
                                        {this.props.subtitle1}
                                  </span>
                                        <br></br>
                                        <span>
                                        {this.props.subtitle2}
                                  </span>
                                    </h2>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>





            </div >

        );
    }
}

export default CSTopHead;




