

import React from 'react';

import gsap from 'gsap';


/**
 * PROPS PROGETTO
 * 
 */


import './CSTextThird';

class CSTextThird extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);


    }

    componentDidMount() {
        console.log(gsap);


    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}

                <section className="casestudy__desc--third">
                    <div className="container">
                        <div className="row">


                            <div className=" col-9 col-offset-3">
                                <div className="casestudy__desc--third__text">
                                    <h2>{this.props.title}</h2>
                                    <p>
                                        {this.props.content}
                                    </p>
                                </div>

                            </div>



                        </div>

                    </div>

                </section>





            </div >

        );
    }
}

export default CSTextThird;




