import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from './CSFullImage/CSFullImage';

import CSMediumImage from './CSMediumImage/CSMediumImage';


import CSParallaxElements from './CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from './CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from './CSTextPrimary/CSTextPrimary';

import CSTextSecondary from './CSTextSecondary/CSTextSecondary';

import CSTextThird from './CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';
import ShareButton from '../../Button/ShareButton';

class CaseStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="Nerarium"
                                    class="title--lg"

                    subtitle1={["Emozionare", <br></br>, "con oggetti di design"]}
                    tags={["Brand identity"]}
                ></CSTopHead>


                {/*Elemento in parallax full screen
                 
                <CSFullImage urlImage="url('https://images.unsplash.com/photo-1597098494674-36c3813a65f3?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ')"></CSFullImage>*/}

                {/*Video in parallax full screen
                 */}
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Nerarium/XL_Nerarium.mp4"></CSVideoFull>


                <CSTextPrimary title="La sfida"
                    content={["Trasportare le sensazioni e la storia di un oggetto fisico nel digitale...", <br></br>, "presto disponibile"]} // Collezionare in un unico luogo di ricerca, oggetti rari e di alto valore stilistico nel campo del design e dell'usato. Trasformando l'acquisto di un prodotto vintage, in un'esperienza evocativa e completa. Dove le sensazioni e la storiadell'oggetto fisico vengono trasportate nel digitale.
                    urlIos=""
                    urlProject=""
                    urlGoogle=""></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://idmstartup.it/HOME.mp4"></CSVideo>*/}

           
                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/L_Nerarium3.jpg')"></CSMediumImage>


                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Il concept tra contenuto", <br></br>, "e contenitore"]}
                    content="Attualmente non divulgabile" //
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1="Ogni prodotto digitale deve essere permeato dall'identità."
                    text2="Costruire significa rispettare delle regole di progettazione."
                    urlImage1Square="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/S_Nerarium1.jpg')"
                    urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/M_Nerarium2.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/M_Nerarium5.jpg')"
                    urlImage4Long="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/M_Nerarium3.jpg')"
                    urlImage5Long="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/M_Nerarium4.jpg')"
                    urlImage6Square="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/S_Nerarium2.jpg')" ></CSParallaxElements>
                    



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">Ad ogni identità<br>
                                </br>
                              il suo Brand book</h2>
                            </div>
                        </div>
                    </div>
                    <CSMediumImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/L_Nerarium2.jpg')"></CSMediumImage>
                </div>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Tra venditore", <br></br>, "e destinatario"]}
                    content="Non divulgabile"
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO*/}
                <CSParallaxElementsAlternate
                    text1="I patterns sono la pelle di un brand."
                    
                    urlImage1Long="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/M_Nerarium6.jpg')"
                    //urlImage2Long="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/M_Nerarium3.jpg')"
                    urlImage3Long="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/M_Nerarium1.jpg')"
                    urlImage4Square="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/S_Nerarium3.gif')"
                ></CSParallaxElementsAlternate>



                {/*Elemento in parallax full screen
                 */}
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://alfatauristudio.com/assets/images/case-studies/Nerarium/L_Nerarium1.jpg')"></CSFullImage>
                </div>

                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText content=" "
                        cta="Condividi" ></CSLastText>
                </div>

                <div className="container">

<ShareButton url={"https://www.alfatauristudio.it/case-study/nerarium"}>


</ShareButton>
</div>



            </div >

        );
    }
}

export default CaseStudy;
