import React from 'react';

import gsap from 'gsap';


/**
 * PROPS PROGETTO
 * 
 */


import './CSParallaxElementsAlternate.scss';

class CSParallaxElementsAlternate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);


    }

    componentDidMount() {
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">

                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <section className="casestudy__desc--parallax">
                    <div className="container">
                        <div className="row">


                            <div className="col-xs-12 col-sm-12 col-md-6">


                                {this.props.urlImage1Long &&
                                    <div className="casestudy__desc--parallax__item--long">

                                        <div className="section-parallax-3">
                                            <div className="parallax-content-3 " style={{ backgroundImage: this.props.urlImage1Long }}>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.props.text1 &&
                                    <div className="casestudy__desc--parallax__text">
                                        {this.props.text1}
                                    </div>
                                }

                                {this.props.urlImage2Long &&
                                    <div className="casestudy__desc--parallax__item--long mt-3">

                                        <div className="section-parallax-4">
                                            <div className="parallax-content-4 " style={{ backgroundImage: this.props.urlImage2Long }}>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">

                                {this.props.urlImage3Long &&

                                    <div className="casestudy__desc--parallax__item--long mt-4">

                                        <div className="section-parallax-4 ">
                                            <div className="parallax-content-4  " style={{ backgroundImage: this.props.urlImage3Long }}>
                                            </div>
                                        </div>
                                    </div>
                                }


                                {this.props.text2 &&
                                    <div className="casestudy__desc--parallax__text">
                                        {this.props.text2}

                                    </div>
                                }

                                {this.props.urlImage4Square &&
                                    <div className="casestudy__desc--parallax__item--square">

                                        <div className="section-parallax-6 section-father">
                                            <div className="parallax-content-6 section-child " style={{ backgroundImage: this.props.urlImage4Square }}>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>



                        </div>

                    </div>

                </section>






            </div >

        );
    }
}

export default CSParallaxElementsAlternate;
