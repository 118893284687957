

import React from 'react';

import gsap from 'gsap';
import SplitText from "gsap/SplitText";

/**
 * PROPS PROGETTO
 * 
 */


import './CSTextPrimary.scss';

class CSTextPrimary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
            timeLineWords: gsap.timeline(),

        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);


    }

    componentDidMount() {


    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">

                {/*TESTO PRINCIPALE RIASSUNTO PROGETTO */}
                <section className="casestudy__desc">
                    <div className="container">
                        <div className="row">

                            <div className="col-xs-12 col-sm-12 col-md-3 ">
                                <div className="casestudy__desc__title">
                                    <h2 >{this.props.title}</h2>
                                </div>

                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-9 ">
                                <div className="casestudy__desc__text">

                                    <p>
                                        {this.props.content}
                                    </p>
                                </div>

                            </div>
                            <div className="col-md-3">
                                {/* E' vuoto ma possiamo metterci qualcosa  */}
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-9">
                                <div className="casestudy__desc__link">
                                    <ul>
                                        {/* TODO applicare magnet a ogni elemento item, Andrea: ricordati anche di mettere il target blank ai link */}
                                        {this.props.urlProject &&
                                            <li className="casestudy__desc__link__item">
                                                <a href={this.props.urlProject} target="_blank">
                                                    Lancia progetto

                                        </a>
                                            </li>
                                        }
                                        {this.props.urlIos &&
                                            <li className="casestudy__desc__link__item">
                                                <a href={this.props.urlIos} target="_blank">
                                                    App Store


                                         </a>
                                            </li>
                                        }
                                        {this.props.urlGoogle &&

                                            <li className="casestudy__desc__link__item">
                                                <a href={this.props.urlGoogle} target="_blank">
                                                    Google Play

                                         </a>
                                            </li>
                                        }
                                        {this.props.urlPdf &&

                                            <li className="casestudy__desc__link__item">
                                                <a href={this.props.urlGoogle} target="_blank">
                                                    Sfogliabile

</a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>






            </div >

        );
    }
}

export default CSTextPrimary;
