import React from 'react';

import gsap from 'gsap';

import CSTopHead from './CSTopHead/CSTopHead';

import CSFullImage from '../CaseStudyRestyle/CSFullImage/CSFullImage';

import CSMediumImage from '../CaseStudyRestyle/CSMediumImage/CSMediumImage';


import CSParallaxElements from '../CaseStudyRestyle/CSParallaxElements/CSParallaxElements';

import CSParallaxElementsAlternate from '../CaseStudyRestyle/CSParallaxElementsAlternate/CSParallaxElementsAlternate';

import CSTextPrimary from '../CaseStudyRestyle/CSTextPrimary/CSTextPrimary';

import CSTextSecondary from '../CaseStudyRestyle/CSTextSecondary/CSTextSecondary';

import CSTextThird from '../CaseStudyRestyle/CSTextThird/CSTextThird';

import CSVideo from './CSVideo/CSVideo';

import CSLastText from './CSLastText/CSLastText';

import CSVideoFull from './CSVideoFull/CSVideoFull';

import ShareButton from '../../Button/ShareButton';


import { Link } from 'gatsby';

/**
 * PROPS PROGETTO
 * 
 */


import './CaseStudyRestyle.scss';

class CaseStudyRestyleApparente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            scrolling: false,
        };

        this.modal = null;

        this.handleOnScroll = this.handleOnScroll.bind(this);

        this.videoOptions1 = {
            autoplay: true,
            muted: true,
            loop: true,
            controls: true,
            sources: [{
                src: 'https://idmstartup.it/HOME.mp4',
                type: 'video/mp4'
            }]
        }
    }

    componentDidMount() {
        console.log(gsap);
        /**
         * Effetto parallax primario
         */
        gsap.utils.toArray(".section-parallax .parallax-content").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-70%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        /**
         * Effetto parallax secondario
         */
        gsap.utils.toArray(".section-parallax-2 .parallax-content-2").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-3 .parallax-content-3").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-4 .parallax-content-4").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-5 .parallax-content-5").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-6 .parallax-content-6").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-7 .parallax-content-7").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-40%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });

        gsap.utils.toArray(".section-parallax-video .parallax-content-video").forEach((section, i) => {
            const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

            gsap.fromTo(section, {
                y: '-30%',
            }, {
                scrollTrigger: {
                    trigger: section.parentElement,
                    scrub: true
                },
                y: 0,
                ease: "none"
            });
        });
    }

    componentWillUnmount() {

    }

    handleOnScroll(event) {

    }

    render() {
        return (
            <div className="casestudy">


                {/**Testo primario col titolo del progetto */}

                <CSTopHead title="Apparente"
                    class="title--md"
                    subtitle1={["Tra proprietari e inquilini,", "tutto in un'app"]}
                ></CSTopHead>

                {/*Video in parallax full screen
                 */}
                <CSVideoFull urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Apparente/XL_apparente.mp4"></CSVideoFull>


                {/*Elemento in parallax full screen
                 
                <CSFullImage urlImage="url('https://images.unsplash.com/photo-1597098494674-36c3813a65f3?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ')"></CSFullImage>*/}



                <CSTextPrimary title="La sfida"
                    content="  Apparente è un brand giovane e carismatico che introduce un innovativo modo di affittare casa completamente online. Una vera rivoluzione nella compravendita immobiliare che apre la porta a concetti come rapidità, creatività e semplicità.
                    L’esperienza di affitto assume in questo modo nuove sfumature, perdendo quella lentezza e quella rigidità sia negli strumenti che nello stile."
                    urlProject="https://proprietari.apparente.com/"
                    urlIos="https://apps.apple.com/it/app/apparente/id1445134428"
                    urlGoogle=""></CSTextPrimary>



                {/*PREVIEW VIDEO CON LOGO ANIMATION

                <CSVideo urlSource="https://www.alfatauristudio.com/assets/images/case-studies/Apparente/XL_apparente.mp4"></CSVideo>*/}



                {/*Elemento in parallax immagine media grandezza
                 */}
                <CSMediumImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/L_apparente1.jpg')"></CSMediumImage>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Cosa significa", <br></br>, " innovare un processo?"]}
                    content="Apparente è tecnologia e freschezza nel mercato immobiliare, ha l’obiettivo di semplificare attraverso i loro strumenti e tecnologie un processo a volte complesso come quello di affittare casa."

                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX*/}
                <CSParallaxElements
                    text1={["Schermate dell'app sviluppata", <br></br>, "in react native."]}
                    text2={["I badge realizzati ", <br></br>, "per l'attività di promoting."]}
                    urlImage1Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/s_apparente.jpg')"
                    urlImage2Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/M_apparente1.jpg')"
                    urlImage3Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/M_apparente2.jpg')"
                    urlImage4Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/M_apparente5.jpg')"
                    urlImage5Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/M_apparente6.jpg')"
                    urlImage6Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/S_apparente4.jpg')" ></CSParallaxElements>



                {/*CONTENUTO DESCRIZIONE TERZA
                <CSTextThird title={["Innovare", <br></br>, " un processo"]}
                    content="  Punto Pago reached out to us to build the Panama Republic’s
                                        very first service to unify multiple accounts into one
                                        convenient location and let users pay their bills online.
                                        In general, Panama’s citizens pay for communal services
                                        twice a month. This means that every two weeks, you had
                                        to track down exactly what you owe, and you had no choice
                                        but to use pay terminals to take care of your bills.
                                        It’s needless to say that paying bills before Punto Pago
                                        was not exactly convenient."></CSTextThird>
                                        */}

                {/*Elemento in parallax full screen
                 */}

                <div className="mt-6">
                    <div className="container">

                        <div className="row">
                            <div className="col-6 text-left">
                                <h2 className="text--big">Design isn't <br>
                                </br>
                              Just detail</h2>
                            </div>
                        </div>
                    </div>
                    <CSMediumImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/L_apparente2.jpg')"></CSMediumImage>
                </div>

                {/*CONTENUTO DESCRIZIONE SECONDARIA*/}
                <CSTextSecondary title={["Due finestre", <br></br>, " verso il futuro del rent house"]}
                    content="Il logo mira a rispecchiare la personalità estroversa e innovativa del brand, due finestre che si aprono e affacciano verso il futuro e tantissime opportunità, è l’idea di un’esperienza che assume nuove sfumature. 
                                 "
                ></CSTextSecondary>


                {/*CONTENUTO DESCRIZIONE ELEMENTI PARALLAX ALTERNATIVO*/}
                <CSParallaxElementsAlternate
                    text1={["Copia del brandbook stampato", <br></br>, " e consegnato"]}
                    text2={["Magliette utilizzate", <br></br>, " per l'attività di promoting"]}
                    urlImage1Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/M_apparente4.jpg')"
                    urlImage2Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/M_apparente7.jpg')"
                    urlImage3Long="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/M_apparente8.jpg')"
                    urlImage4Square="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/S_apparente2.jpg')"
                ></CSParallaxElementsAlternate>



                {/*Elemento in parallax full screen
                 */}
                <div className="mt-6">
                    <CSFullImage urlImage="url('https://www.alfatauristudio.com/assets/images/case-studies/Apparente/XL_apparente.jpg')"></CSFullImage>
                </div>

                {/*Elemento in parallax full screen
                 */}
                <div className="mt-4">
                    <CSLastText content=" Vele, grandi cartelloni, locandine e volantini sono stati distribuiti nella città di Roma per far conoscere il brand. La start-up è attualmente in fundingsearching."
                        cta="Condividi " ></CSLastText>
                </div>

                <div className="container">

                    <ShareButton url={"https://www.alfatauristudio.it/case-study/apparente"}>


                    </ShareButton>
                </div>


            </div >

        );
    }
}

export default CaseStudyRestyleApparente;
